<template>
  <form>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Employee <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-autocomplete
              v-model="form.employeeId"
              :error-messages="employeeIdErrors"
              required
              outlined
              dense
              flat
              item-text="name"
              item-value="id"
              background-color="white"
              @input="$v.form.employeeId.$touch()"
              @blur="$v.form.employeeId.$touch()"
              ref="employeeId"
              :items="employees"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Account Number <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-text-field
              v-model="form.accountNumber"
              :error-messages="accountNumberErrors"
              required
              outlined
              dense
              flat
              background-color="white"
              @input="$v.form.accountNumber.$touch()"
              @blur="$v.form.accountNumber.$touch()"
              ref="accountNumber"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Bank Name <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-text-field
              v-model="form.bankName"
              :error-messages="bankNameErrors"
              required
              outlined
              dense
              flat
              background-color="white"
              @input="$v.form.bankName.$touch()"
              @blur="$v.form.bankName.$touch()"
              ref="bankName"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Owner Name <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-text-field
              v-model="form.ownerName"
              :error-messages="ownerNameErrors"
              required
              outlined
              dense
              flat
              background-color="white"
              @input="$v.form.ownerName.$touch()"
              @blur="$v.form.ownerName.$touch()"
              ref="ownerName"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" cols="12">
        <v-btn class="mr-4" @click="submit" color="primary">
          submit
        </v-btn>
        <v-btn @click="clear">
          clear
        </v-btn>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  mixins: [validationMixin],

  validations: {
    form: {
      employeeId: { required },
      accountNumber: { required },
      bankName: { required },
      ownerName: { required },
    },
  },

  data: () => ({
    defaultForm: {
      employeeId: null,
      accountNumber: "",
      bankName: "",
      ownerName: "",
    },
    form: {},
  }),

  computed: {
    employeeIdErrors() {
      const errors = [];
      if (!this.$v.form.employeeId.$dirty) return errors;
      !this.$v.form.employeeId.required && errors.push("Employee is required");
      return errors;
    },
    accountNumberErrors() {
      const errors = [];
      if (!this.$v.form.accountNumber.$dirty) return errors;
      !this.$v.form.accountNumber.required && errors.push("Account Number is required");
      return errors;
    },
    bankNameErrors() {
      const errors = [];
      if (!this.$v.form.bankName.$dirty) return errors;
      !this.$v.form.bankName.required && errors.push("Bank Name is required.");
      return errors;
    },
    ownerNameErrors() {
      const errors = [];
      if (!this.$v.form.ownerName.$dirty) return errors;
      !this.$v.form.ownerName.required && errors.push("Owner Name is required");
      return errors;
    },
    ...mapState("external", ["employees"]),
  },

  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        for (let key in Object.keys(this.$v.form)) {
          const input = Object.keys(this.$v.form)[key];
          if (input.includes("$")) return false;
          if (this.$v.form[input].$error) {
            this.$refs[input].focus();
            break;
          }
        }
      } else {
        this.$store.dispatch("accountNumber/create", this.form);
      }
    },
    clear() {
      this.$v.form.$reset();
      this.form = Object.assign({}, this.defaultForm);
    },
  },

  mounted() {
    this.$store.dispatch("external/getEmployees");
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
